/********************
    EKONSILIO
********************/
.ekonsilio {
    &__palette {
        &--grid {
            display: grid;
            grid-template-columns: repeat(9, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 40px;

            margin-top: 32px;
        }

        &--item {
            display: flex;
            flex-direction: column;
            align-items: stretch;

            gap: 12px;

            height: 130px;

            p {
                font-size: 1rem;
                line-height: 1rem;
                font-weight: 400;

                text-align: center;

                color: $white;

                opacity: 0.72;
            }

            div {
                flex: 1;

                border-radius: 60px;
            }
        }

        &--item:nth-child(3) {
            height: (5 * 102px) + 28px;
        }

        &--item:nth-child(4) {
            height: (4 * 102px) + 28px;
        }

        &--item:nth-child(n + 5) {
            height: (3 * 102px) + 28px;
        }
    }

    &__chat {
        display: flex;
        justify-content: space-between;

        &--user {
            position: relative;
            width: 260px;
            height: 260px;

            border-radius: 130px 130px 130px 0;

            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            span {
                position: absolute;

                top: 0;
                padding: 12px;
                background-color: $white;
                border-radius: 20px 20px 0 20px;
            }

            &.left {
                border-radius: 130px 130px 130px 0;

                span {
                    left: 0;
                    border-radius: 20px 20px 0 20px;
                }
            }

            &.right {
                border-radius: 130px 130px 0 130px;

                span {
                    right: 0;
                    border-radius: 20px 20px 20px 0;
                }
            }
        }
    }

    &__icons-font {
        .container {
            display: flex;
            align-items: items-stretch;

            gap: 72px;

            & > div {
                flex: 1;
            }
        }
    }

    &__icons {
        &--grid {
            margin-top: 24px;
        }
    }

    &__font {
        display: flex;
        flex-direction: column;

        &--content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;

            margin-top: 24px;

            color: $white;

            & > div:nth-child(2) {
                position: relative;

                flex: 1;
            }

            h3 {
                font-size: 2.2rem;
                font-weight: 600;

                opacity: 0.8;
            }

            p {
                position: absolute;

                top: 50%;
                left: 0;

                margin-top: -72px;

                font-size: 1.6rem;
                letter-spacing: 0.2rem;
                line-height: 3rem;
            }
        }

        &--tabs {
            display: flex;
            justify-content: flex-start;

            & > div {
                display: flex;
                gap: 2px;

                padding: 4px;

                border-radius: 12px;
                background-color: #c5c7c9;
            }

            &-item {
                width: 56px;
                padding: 8px 0;

                font-size: 1rem;
                font-weight: 400;
                line-height: 1.2rem;
                text-align: center;

                border-radius: 10px;

                cursor: pointer;
            }
        }
    }

    &__components {
        img {
            display: block;
        }

        &--grid {
            display: flex;
            align-items: items-stretch;
            gap: 40px;

            margin-top: 24px;

            & > div:nth-child(2) {
                display: flex;
                flex-direction: column;
                flex: 1;

                gap: 40px;

                & > div {
                    display: flex;
                    align-items: items-stretch;
                    gap: 40px;

                    div {
                        display: flex;
                        flex-direction: column;
                        gap: 40px;
                    }
                }
            }
        }
    }
}
