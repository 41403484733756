@font-face {
  font-family: Averta;
  src: url("averta-lightitalic-webfont.5f4f6c1b.eot");
  src: url("averta-lightitalic-webfont.5f4f6c1b.eot#iefix") format("embedded-opentype"), url("averta-lightitalic-webfont.d2e7dcff.woff2") format("woff2"), url("averta-lightitalic-webfont.0dfdff84.woff") format("woff"), url("averta-lightitalic-webfont.1831c5ff.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Averta;
  src: url("averta-light-webfont.630bdd84.eot");
  src: url("averta-light-webfont.630bdd84.eot#iefix") format("embedded-opentype"), url("averta-light-webfont.c0a37f8e.woff2") format("woff2"), url("averta-light-webfont.5328d06d.woff") format("woff"), url("averta-light-webfont.c1e71e3b.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Averta;
  src: url("averta-regularitalic-webfont.566a3522.eot");
  src: url("averta-regularitalic-webfont.566a3522.eot#iefix") format("embedded-opentype"), url("averta-regularitalic-webfont.159b223a.woff2") format("woff2"), url("averta-regularitalic-webfont.d55d6cff.woff") format("woff"), url("averta-regularitalic-webfont.511b1380.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: Averta;
  src: url("averta-regular-webfont.021e0cc4.eot");
  src: url("averta-regular-webfont.021e0cc4.eot#iefix") format("embedded-opentype"), url("averta-regular-webfont.3abb490c.woff2") format("woff2"), url("averta-regular-webfont.8ea2df68.woff") format("woff"), url("averta-regular-webfont.8a4e1f48.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Averta;
  src: url("averta-semibolditalic-webfont.557c707e.eot");
  src: url("averta-semibolditalic-webfont.557c707e.eot#iefix") format("embedded-opentype"), url("averta-semibolditalic-webfont.c323922f.woff2") format("woff2"), url("averta-semibolditalic-webfont.6d7e0048.woff") format("woff"), url("averta-semibolditalic-webfont.c611f45d.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: Averta;
  src: url("averta-semibold-webfont.6f043584.eot");
  src: url("averta-semibold-webfont.6f043584.eot#iefix") format("embedded-opentype"), url("averta-semibold-webfont.0a303423.woff2") format("woff2"), url("averta-semibold-webfont.5d144775.woff") format("woff"), url("averta-semibold-webfont.f59a31c8.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Averta;
  src: url("averta-bolditalic-webfont.c89e835c.eot");
  src: url("averta-bolditalic-webfont.c89e835c.eot#iefix") format("embedded-opentype"), url("averta-bolditalic-webfont.d43d3de5.woff2") format("woff2"), url("averta-bolditalic-webfont.24899b19.woff") format("woff"), url("averta-bolditalic-webfont.5cbfe3bd.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Averta;
  src: url("averta-bold-webfont.0d908d2f.eot");
  src: url("averta-bold-webfont.0d908d2f.eot#iefix") format("embedded-opentype"), url("averta-bold-webfont.e43d98ca.woff2") format("woff2"), url("averta-bold-webfont.edc0a8e2.woff") format("woff"), url("averta-bold-webfont.d4b0be74.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

* {
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  padding: 0;
}

:focus {
  outline: 0;
}

html, body, #root {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
}

body, input, button {
  font-family: Averta, Arial, Helvetica, sans-serif;
  font-size: 16px;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

button {
  cursor: pointer;
}

body {
  background-image: url("bg.363dcf94.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;
}

main {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

article {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

@media screen and (max-width: 1328px) {
  .container {
    padding: 0 24px;
  }
}

@media screen and (max-width: 640px) {
  .container {
    padding: 0 16px;
  }
}

header {
  z-index: 10;
  padding: 24px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

@media screen and (max-width: 640px) {
  header {
    padding: 16px 0;
  }
}

header nav, header ul, header .profile, header .actions {
  align-items: center;
  display: flex;
}

@media screen and (max-width: 432px) {
  header nav, header ul, header .profile, header .actions {
    align-items: flex-start;
  }
}

header nav, header ul {
  justify-content: space-between;
}

header .profile {
  gap: 16px;
}

@media screen and (max-width: 640px) {
  header .profile {
    gap: 8px;
  }
}

@media screen and (max-width: 432px) {
  header .profile {
    max-width: 260px;
    overflow: hidden;
  }
}

header .profile__avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

@media screen and (max-width: 640px) {
  header .profile__avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}

header .profile__details {
  color: #fff;
}

header .profile__details p {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
}

@media screen and (max-width: 640px) {
  header .profile__details p {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.375rem;
  }
}

header .profile__details p:last-child {
  opacity: .8;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
}

@media screen and (max-width: 640px) {
  header .profile__details p:last-child {
    font-size: .875rem;
    line-height: 1.125rem;
  }
}

@media screen and (max-width: 432px) {
  header .profile__details p:last-child {
    font-size: .825rem;
    line-height: 1.125rem;
  }
}

header a {
  color: #fff;
  backdrop-filter: blur(6px) saturate(120%);
  cursor: pointer;
  background: linear-gradient(to bottom right, #ffffff4d, #ffffff1a), linear-gradient(to bottom left, #21252914, #ffffff06);
  border: 1px solid #ffffff1a;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.75rem;
  text-decoration: none;
  transition: all .2s ease-in-out;
  display: flex;
}

header a:hover {
  background-color: #ffffff1a;
  text-decoration: none;
  transform: translateY(-2px);
  box-shadow: 0 8px 24px #ffffff4d, 0 4px 12px #2125290d;
}

@media screen and (max-width: 640px) {
  header a {
    border-radius: 8px;
    padding: 8px 12px;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

@media screen and (max-width: 432px) {
  header a {
    border-radius: 8px;
    padding: 10px;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.25rem;
  }
}

header .routers {
  gap: 40px;
}

header .routers ul {
  gap: 24px;
}

header .routers a {
  color: #fff;
  font-size: 1.125rem;
  font-weight: 300;
  position: relative;
}

header .routers a:before {
  content: "";
  width: 100%;
  height: 1px;
  pointer-events: none;
  transform-origin: 50% 100%;
  clip-path: polygon(0% 0%, 0% 100%, 0 100%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%, 100% 100%, 100% 0%);
  background-color: #fff6;
  transition: clip-path .3s, transform .3s cubic-bezier(.2, 1, .8, 1);
  position: absolute;
  top: 100%;
  left: 0;
}

header .routers a span {
  transition: transform .3s cubic-bezier(.2, 1, .8, 1);
  display: inline-block;
}

header .routers a:hover:before {
  clip-path: polygon(0% 0%, 0% 100%, 50% 100%, 50% 0, 50% 0, 50% 100%, 50% 100%, 0 100%, 100% 100%, 100% 0%);
  transform: translate3d(0, 2px, 0)scale3d(1.08, 3, 1);
}

header .routers a:hover span {
  transform: translate3d(0, -2px, 0);
}

.card {
  color: #fff;
  backdrop-filter: blur(6px) saturate(120%);
  -webkit-backdrop-filter: blur(6px) saturate(120%);
  transform-style: preserve-3d;
  cursor: pointer;
  background: linear-gradient(to bottom right, #ffffff4d, #ffffff1a), linear-gradient(to bottom left, #21252914, #21252906);
  border: 1px solid #fff3;
  border-radius: 20px;
  flex-direction: column;
  align-items: stretch;
  padding: 24px;
  transition: all .2s ease-in-out;
  display: flex;
  box-shadow: 0 8px 16px #fff3;
}

.card--hover:hover {
  box-shadow: 0 16px 32px #ffffff4d, 0 8px 16px #2125290d;
}

.card--dark {
  backdrop-filter: blur(8px) brightness(120%) saturate(120%);
  -webkit-backdrop-filter: blur(8px) brightness(120%) saturate(120%);
  background: linear-gradient(to bottom right, #21252933, #2125290d), linear-gradient(to bottom left, #ffffff14, #ffffff06);
  border: 1px solid #2125290d;
  box-shadow: 0 8px 16px #21252933;
}

.card--dark:hover {
  box-shadow: 0 16px 32px #21252933, 0 8px 16px #ffffff0d;
}

.card__cover {
  width: 100%;
  max-width: 840px;
  border-radius: 16px;
}

.card__header {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 64px;
  display: flex;
}

.card__icon {
  width: 40px;
  height: 40px;
  background-color: #ffffff4d;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.card__icon img {
  width: 24px;
  height: 24px;
}

.card__date {
  opacity: .72;
  font-size: .875rem;
  font-weight: 300;
}

.card__body {
  flex-direction: column;
  gap: 32px;
  display: flex;
}

.card__award, .card__location {
  text-transform: capitalize;
  opacity: .96;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1rem;
}

.card__title {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.75rem;
}

.card__tasks {
  flex-direction: column;
  gap: 4px;
  display: flex;
}

.card__task {
  opacity: .8;
  font-size: .875rem;
  font-weight: 300;
  line-height: 1rem;
}

.card .separator {
  width: 60px;
  height: 10px;
}

.project {
  padding-bottom: 100px;
  position: relative;
}

.project__intro--mask {
  height: 100vh;
  z-index: -1;
  background: radial-gradient(circle at 50% 50%, #2125291a 0%, #212529cc 100%);
  align-items: stretch;
  display: flex;
  position: fixed;
  inset: 0;
}

.project__intro--mask div {
  width: 100%;
  height: 100%;
  background-color: #212529;
}

.project__intro--cover {
  height: 100vh;
  perspective: 1200px;
  justify-content: center;
  align-items: center;
  padding-top: 108px;
  display: flex;
}

@media screen and (max-width: 640px) {
  .project__intro--cover {
    padding-top: 72px;
  }
}

@media screen and (max-width: 432px) {
  .project__intro--cover {
    padding-top: 90px;
  }
}

.project__intro--cover .card {
  margin-top: -108px;
}

@media screen and (max-width: 640px) {
  .project__intro--cover .card {
    margin-top: -72px;
  }
}

@media screen and (max-width: 432px) {
  .project__intro--cover .card {
    margin-top: -90px;
  }
}

.project__header {
  border-top: 1px solid #ffffff1a;
  border-bottom: 1px solid #ffffff1a;
  margin: -126px 0 80px;
  padding: 32px 0;
}

.project__header .container {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.project__header .container > div {
  flex: 1;
}

.project__header--company {
  align-items: center;
  gap: 16px;
  display: flex;
}

.project__header--company div {
  width: 60px;
  height: 60px;
  background-color: #ffffff1a;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.project__header--company div img {
  width: 32px;
  height: 32px;
}

.project__header--company h3 {
  color: #fff;
  font-size: 2rem;
  font-weight: 500;
}

.project__header--date {
  opacity: .72;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  display: flex;
}

.project__header--date p {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 300;
}

.project__content {
  flex-direction: column;
  align-items: stretch;
  gap: 200px;
  display: flex;
}

.project__content blockquote {
  text-align: center;
  color: #fff;
  font-size: 5rem;
  font-weight: 300;
  line-height: 6rem;
}

@media screen and (max-width: 640px) {
  .project__content blockquote {
    font-size: 4rem;
    line-height: 5.2rem;
  }
}

@media screen and (max-width: 432px) {
  .project__content blockquote {
    font-size: 3.2rem;
    line-height: 4.5rem;
  }
}

.project__content h2 {
  color: #fff;
  font-size: 2.8rem;
  font-weight: 500;
}

.project__content h2 + p {
  color: #fff;
  opacity: .8;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
}

.project__start .container {
  align-items: center;
  gap: 120px;
  display: flex;
}

.project__start .container > div {
  flex: 1;
}

.project__start--left {
  flex-direction: column;
  gap: 24px;
  display: flex;
}

.project__start--left h2 {
  max-width: 75%;
}

.project__start--left div {
  gap: 24px;
  display: flex;
}

.hero {
  height: calc(100vh - 108px);
  margin-top: 108px;
  position: relative;
}

@media screen and (max-width: 640px) {
  .hero {
    height: calc(100vh - 72px);
    margin-top: 72px;
  }
}

@media screen and (max-width: 432px) {
  .hero {
    height: calc(100vh - 90px);
    margin-top: 90px;
  }
}

.hero__title {
  color: #fff;
  flex-direction: column;
  margin-top: 11%;
  font-size: 5rem;
  font-weight: 400;
  line-height: 6rem;
  display: flex;
}

@media screen and (max-width: 640px) {
  .hero__title {
    margin-top: 50px;
    font-size: 4rem;
    line-height: 5.2rem;
  }
}

@media screen and (max-width: 432px) {
  .hero__title {
    text-align: center;
    margin-top: 32px;
    font-size: 3.2rem;
    line-height: 4.5rem;
  }

  .hero__title span {
    display: block;
  }
}

.hero .illustration {
  width: 100%;
  z-index: -1;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.hero .illustration .ground {
  width: 100%;
  height: 50%;
  clip-path: polygon(70% 0, 100% 30%, 100% 100%, 0 100%, 0 50%);
  backdrop-filter: blur(6px) saturate(120%);
  -webkit-backdrop-filter: blur(6px) saturate(120%);
  filter: blur(6px) saturate(120%);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.hero .illustration .ground:before {
  content: "";
  background: linear-gradient(#fff9, #0000), linear-gradient(#21252914, #0000);
  position: absolute;
  inset: -6px -6px 0;
}

.hero .illustration .cube {
  width: 220px;
  height: 182px;
  transform-origin: 0;
  clip-path: polygon(50% 0%, 100% 14px, 100% calc(100% - 20px), 50% 100%, 0% calc(100% - 20px), 0% 14px);
  backdrop-filter: blur(8px) saturate(120%);
  -webkit-backdrop-filter: blur(6px) saturate(120%);
  filter: blur(8px) saturate(120%);
  position: absolute;
}

.hero .illustration .cube__face {
  width: 220px;
  height: 28px;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  position: relative;
}

.hero .illustration .cube__face:before {
  content: "";
  z-index: -1;
  background: linear-gradient(#fff6, #ffffff1a), linear-gradient(to top, #21252914, #2125290d);
  position: absolute;
  inset: -8px;
}

.hero .illustration .cube__face--left, .hero .illustration .cube__face--right {
  width: 110px;
  height: 168px;
  position: absolute;
  top: 14px;
}

.hero .illustration .cube__face--left {
  clip-path: polygon(0% 0%, 100% 14px, 100% 100%, 0% calc(100% - 20px));
  left: 0;
}

.hero .illustration .cube__face--left:before {
  content: "";
  z-index: -1;
  background: linear-gradient(to bottom right, #fffc, #ffffff4d), linear-gradient(to bottom left, #21252914, #21252906);
  position: absolute;
  inset: -8px;
}

.hero .illustration .cube__face--right {
  clip-path: polygon(0 14px, 100% 0%, 100% calc(100% - 20px), 0% 100%);
  right: 0;
}

.hero .illustration .cube__face--right:before {
  content: "";
  z-index: -1;
  background: linear-gradient(to bottom left, #ffffffb3, #fff3), linear-gradient(to bottom right, #21252920, #21252906);
  position: absolute;
  inset: -8px;
}

.hero .illustration .cube:nth-child(1) {
  top: 7%;
  left: 53.25%;
}

@media screen and (max-width: 1690px) {
  .hero .illustration .cube:nth-child(1) {
    top: 7%;
    left: 53.25%;
  }
}

@media screen and (max-width: 1440px) {
  .hero .illustration .cube:nth-child(1) {
    top: 5%;
    left: 52%;
  }
}

.hero .illustration .cube:nth-child(2) {
  top: 7%;
  left: 63%;
}

@media screen and (max-width: 1690px) {
  .hero .illustration .cube:nth-child(2) {
    top: 7%;
    left: 63%;
  }
}

@media screen and (max-width: 1440px) {
  .hero .illustration .cube:nth-child(2) {
    top: 9%;
    left: 63%;
  }
}

.hero .illustration .cube:nth-child(3) {
  top: 10%;
  left: 78.5%;
}

@media screen and (max-width: 1690px) {
  .hero .illustration .cube:nth-child(3) {
    top: 10%;
    left: 80.5%;
  }
}

@media screen and (max-width: 1440px) {
  .hero .illustration .cube:nth-child(3) {
    top: 11%;
    left: 80.5%;
  }
}

.hero .illustration .cube:nth-child(4) {
  top: 16%;
  left: 98.5%;
}

@media screen and (max-width: 1690px) {
  .hero .illustration .cube:nth-child(4) {
    top: 16%;
    left: 98.5%;
  }
}

.hero .illustration .cube:nth-child(5) {
  top: 30%;
  left: 65%;
}

@media screen and (max-width: 1690px) {
  .hero .illustration .cube:nth-child(5) {
    top: 30%;
    left: 65%;
  }
}

.hero .illustration .cube:nth-child(6) {
  top: 30%;
  left: 61%;
}

@media screen and (max-width: 1690px) {
  .hero .illustration .cube:nth-child(6) {
    top: 30%;
    left: 59.5%;
  }
}

@media screen and (max-width: 1440px) {
  .hero .illustration .cube:nth-child(6) {
    top: 30%;
    left: 61%;
  }
}

.hero .illustration .cube:nth-child(7) {
  top: 30%;
  left: 82.5%;
}

@media screen and (max-width: 1690px) {
  .hero .illustration .cube:nth-child(7) {
    top: 30%;
    left: 86%;
  }
}

.hero .illustration .cube:nth-child(8) {
  top: 42%;
  left: 52%;
}

@media screen and (max-width: 1690px) {
  .hero .illustration .cube:nth-child(8) {
    top: 42%;
    left: 50%;
  }
}

@media screen and (max-width: 1440px) {
  .hero .illustration .cube:nth-child(8) {
    top: 40%;
    left: 51%;
  }
}

.hero .illustration .cube:nth-child(9) {
  top: 48%;
  left: 68.5%;
}

@media screen and (max-width: 1690px) {
  .hero .illustration .cube:nth-child(9) {
    top: 48%;
    left: 68.5%;
  }
}

@media screen and (max-width: 1440px) {
  .hero .illustration .cube:nth-child(9) {
    top: 46%;
    left: 68.5%;
  }
}

.hero .illustration .cube:nth-child(10) {
  top: 50%;
  left: 81%;
}

@media screen and (max-width: 1690px) {
  .hero .illustration .cube:nth-child(10) {
    top: 50%;
    left: 81%;
  }
}

.hero .illustration .cube:nth-child(11) {
  top: 66%;
  left: 63%;
}

@-moz-document url-prefix() {
  .hero .illustration .cube:nth-child(11) {
    display: none;
  }
}

@media screen and (max-width: 1690px) {
  .hero .illustration .cube:nth-child(11) {
    top: 66%;
    left: 58%;
  }
}

@media screen and (max-width: 1440px) {
  .hero .illustration .cube:nth-child(11) {
    top: 66%;
    left: 62%;
  }
}

.projects {
  margin: -200px 0 100px;
  position: relative;
}

@media screen and (max-width: 640px) {
  .projects {
    margin: -120px 0 100px;
  }
}

.projects__grid {
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  display: grid;
}

.projects__col {
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  display: flex;
}

.experiences {
  background: linear-gradient(#0000, #212529cc);
  padding: 160px 0 100px;
}

@media screen and (max-width: 640px) {
  .experiences {
    padding: 100px 0 40px;
  }
}

.experiences__grid {
  grid-gap: 24px;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.experiences__col {
  flex-direction: column;
  grid-area: 1 / 2 / 2 / 3;
  justify-content: flex-end;
  align-items: stretch;
  display: flex;
}

.experiences__col:first-child {
  grid-area: 1 / 1 / 3 / 2;
}

.experiences__col:last-child {
  grid-area: 2 / 2 / 3 / 3;
}

.experiences__col > * {
  height: 100%;
}

.ekonsilio__palette--grid {
  grid-column-gap: 40px;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(9, 1fr);
  margin-top: 32px;
  display: grid;
}

.ekonsilio__palette--item {
  height: 130px;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
  display: flex;
}

.ekonsilio__palette--item p {
  text-align: center;
  color: #fff;
  opacity: .72;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1rem;
}

.ekonsilio__palette--item div {
  border-radius: 60px;
  flex: 1;
}

.ekonsilio__palette--item:nth-child(3) {
  height: 538px;
}

.ekonsilio__palette--item:nth-child(4) {
  height: 436px;
}

.ekonsilio__palette--item:nth-child(n+5) {
  height: 334px;
}

.ekonsilio__chat {
  justify-content: space-between;
  display: flex;
}

.ekonsilio__chat--user {
  width: 260px;
  height: 260px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 130px 130px 130px 0;
  position: relative;
}

.ekonsilio__chat--user span {
  background-color: #fff;
  border-radius: 20px 20px 0;
  padding: 12px;
  position: absolute;
  top: 0;
}

.ekonsilio__chat--user.left {
  border-radius: 130px 130px 130px 0;
}

.ekonsilio__chat--user.left span {
  border-radius: 20px 20px 0;
  left: 0;
}

.ekonsilio__chat--user.right {
  border-radius: 130px 130px 0;
}

.ekonsilio__chat--user.right span {
  border-radius: 20px 20px 20px 0;
  right: 0;
}

.ekonsilio__icons-font .container {
  align-items: items-stretch;
  gap: 72px;
  display: flex;
}

.ekonsilio__icons-font .container > div {
  flex: 1;
}

.ekonsilio__icons--grid {
  margin-top: 24px;
}

.ekonsilio__font {
  flex-direction: column;
  display: flex;
}

.ekonsilio__font--content {
  color: #fff;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  margin-top: 24px;
  display: flex;
}

.ekonsilio__font--content > div:nth-child(2) {
  flex: 1;
  position: relative;
}

.ekonsilio__font--content h3 {
  opacity: .8;
  font-size: 2.2rem;
  font-weight: 600;
}

.ekonsilio__font--content p {
  letter-spacing: .2rem;
  margin-top: -72px;
  font-size: 1.6rem;
  line-height: 3rem;
  position: absolute;
  top: 50%;
  left: 0;
}

.ekonsilio__font--tabs {
  justify-content: flex-start;
  display: flex;
}

.ekonsilio__font--tabs > div {
  background-color: #c5c7c9;
  border-radius: 12px;
  gap: 2px;
  padding: 4px;
  display: flex;
}

.ekonsilio__font--tabs-item {
  width: 56px;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  padding: 8px 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2rem;
}

.ekonsilio__components img {
  display: block;
}

.ekonsilio__components--grid {
  align-items: items-stretch;
  gap: 40px;
  margin-top: 24px;
  display: flex;
}

.ekonsilio__components--grid > div:nth-child(2) {
  flex-direction: column;
  flex: 1;
  gap: 40px;
  display: flex;
}

.ekonsilio__components--grid > div:nth-child(2) > div {
  align-items: items-stretch;
  gap: 40px;
  display: flex;
}

.ekonsilio__components--grid > div:nth-child(2) > div div {
  flex-direction: column;
  gap: 40px;
  display: flex;
}

/*# sourceMappingURL=index.15be1e69.css.map */
