@import "typography.scss";
@import "reset.scss";

/********************
    VARIABLES
********************/
$primary: #0d6efd;
$dark: #212529;
$white: white;

$padding: 24px;
$container: 1280px;

@mixin mediamaxwidth($max) {
    @media screen and (max-width: $max) {
        @content;
    }
}

/********************
    MISC
********************/
body {
    background-image: url(../images/bg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;

    overflow: hidden;
}

main {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
}

article {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.container {
    width: 100%;
    max-width: $container;
    margin: 0 auto;

    @include mediamaxwidth($container + (24px * 2)) {
        padding: 0 24px;
    }

    @include mediamaxwidth(640px) {
        padding: 0 16px;
    }
}

/********************
    HEADER
********************/
header {
    position: fixed;

    top: 0;
    left: 0;
    right: 0;

    z-index: 10;

    padding: 24px 0;

    @include mediamaxwidth(640px) {
        padding: 16px 0;
    }

    nav,
    ul,
    .profile,
    .actions {
        display: flex;
        align-items: center;

        @include mediamaxwidth(432px) {
            align-items: flex-start;
        }
    }

    nav,
    ul {
        justify-content: space-between;
    }

    .profile {
        gap: 16px;

        @include mediamaxwidth(640px) {
            gap: 8px;
        }

        @include mediamaxwidth(432px) {
            max-width: 260px;
            overflow: hidden;
        }

        &__avatar {
            width: 60px;
            height: 60px;
            border-radius: 50%;

            @include mediamaxwidth(640px) {
                width: 40px;
                height: 40px;
                border-radius: 50%;
            }
        }

        &__details {
            color: $white;

            p {
                font-size: 1.5rem;
                font-weight: 400;

                line-height: 2rem;

                @include mediamaxwidth(640px) {
                    font-size: 1.125rem;
                    font-weight: 400;

                    line-height: 1.375rem;
                }

                &:last-child {
                    font-size: 1rem;
                    font-weight: 300;
                    line-height: 1.5rem;

                    opacity: 0.8;

                    @include mediamaxwidth(640px) {
                        font-size: 0.875rem;
                        line-height: 1.125rem;
                    }

                    @include mediamaxwidth(432px) {
                        font-size: 0.825rem;
                        line-height: 1.125rem;
                    }
                }
            }
        }
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 12px 16px;

        font-size: 1rem;
        font-weight: 600;
        line-height: 1.75rem;

        text-decoration: none;

        color: white;

        border-radius: 12px;
        border: 1px solid rgba($white, 0.1);

        background: linear-gradient(
                to bottom right,
                rgba($white, 0.3),
                rgba($white, 0.1)
            ),
            linear-gradient(
                to bottom left,
                rgba($dark, 0.08),
                rgba($white, 0.025)
            );

        backdrop-filter: blur(6px) saturate(120%);

        transition: all ease-in-out 0.2s;

        cursor: pointer;

        &:hover {
            text-decoration: none;

            transform: translateY(-2px);
            background-color: rgba($white, 0.1);
            box-shadow: 0 8px 24px 0px rgba($white, 0.3),
                0 4px 12px 0 rgba($dark, 0.05);
        }

        @include mediamaxwidth(640px) {
            padding: 8px 12px;

            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.5rem;

            border-radius: 8px;
        }

        @include mediamaxwidth(432px) {
            padding: 10px 10px;

            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.25rem;

            border-radius: 8px;
        }
    }

    .routers {
        gap: 40px;

        ul {
            gap: 24px;
        }

        a {
            position: relative;

            font-size: 1.125rem;
            font-weight: 300;

            color: $white;

            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 1px;
                top: 100%;
                left: 0;
                pointer-events: none;
                transform-origin: 50% 100%;
                transition: clip-path 0.3s,
                    transform 0.3s cubic-bezier(0.2, 1, 0.8, 1);

                clip-path: polygon(
                    0% 0%,
                    0% 100%,
                    0 100%,
                    0 0,
                    100% 0,
                    100% 100%,
                    0 100%,
                    0 100%,
                    100% 100%,
                    100% 0%
                );

                background-color: rgba($white, 0.4);
            }

            span {
                display: inline-block;
                transition: transform 0.3s cubic-bezier(0.2, 1, 0.8, 1);
            }

            &:hover::before {
                transform: translate3d(0, 2px, 0) scale3d(1.08, 3, 1);
                clip-path: polygon(
                    0% 0%,
                    0% 100%,
                    50% 100%,
                    50% 0,
                    50% 0,
                    50% 100%,
                    50% 100%,
                    0 100%,
                    100% 100%,
                    100% 0%
                );
            }

            &:hover span {
                transform: translate3d(0, -2px, 0);
            }
        }
    }
}

/********************
    COMPONENTS
********************/
@import "card.scss";
@import "project.scss";
/********************
    PAGES
********************/
@import "home.scss";
@import "ekonsilio.scss";
