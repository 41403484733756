/********************
    PROJECT
********************/
.project {
    position: relative;
    padding-bottom: 100px;

    &__intro {
        &--mask {
            position: fixed;
            inset: 0;

            display: flex;
            align-items: stretch;

            height: 100vh;

            background: radial-gradient(
                circle at center top 50%,
                rgba($dark, 0.1) 0%,
                rgba($dark, 0.8) 100%
            );

            z-index: -1;

            div {
                width: 100%;
                height: 100%;

                background-color: $dark;
            }
        }

        &--cover {
            display: flex;
            align-items: center;
            justify-content: center;

            height: 100vh;

            padding-top: 108px;

            perspective: 1200px;

            @include mediamaxwidth(640px) {
                padding-top: 72px;
            }

            @include mediamaxwidth(432px) {
                padding-top: 90px;
            }

            .card {
                margin-top: -108px;

                @include mediamaxwidth(640px) {
                    margin-top: -72px;
                }

                @include mediamaxwidth(432px) {
                    margin-top: -90px;
                }
            }
        }
    }

    &__header {
        margin: -126px 0 80px;

        padding: 32px 0;

        border-top: solid 1px rgba($white, 0.1);
        border-bottom: solid 1px rgba($white, 0.1);

        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;

            & > div {
                flex: 1;
            }
        }

        &--company {
            display: flex;
            align-items: center;

            gap: 16px;

            div {
                display: flex;
                align-items: center;
                justify-content: center;

                width: 60px;
                height: 60px;

                border-radius: 20px;

                background-color: rgba($white, 0.1);

                img {
                    width: 32px;
                    height: 32px;
                }
            }

            h3 {
                font-size: 2rem;
                font-weight: 500;

                color: $white;
            }
        }

        &--date {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            gap: 8px;

            opacity: 0.72;

            p {
                font-size: 1.2rem;
                font-weight: 300;

                color: $white;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: stretch;

        gap: 200px;

        blockquote {
            font-size: 5rem;
            font-weight: 300;

            line-height: 6rem;

            text-align: center;

            color: $white;

            @include mediamaxwidth(640px) {
                font-size: 4rem;
                line-height: 5.2rem;
            }

            @include mediamaxwidth(432px) {
                font-size: 3.2rem;
                line-height: 4.5rem;
            }
        }

        h2 {
            font-size: 2.8rem;
            font-weight: 500;

            color: $white;

            & + p {
                font-size: 1rem;
                line-height: 1.5rem;
                font-weight: 300;

                color: $white;

                opacity: 0.8;
            }
        }
    }

    &__start {
        .container {
            display: flex;
            align-items: center;

            gap: 120px;

            & > div {
                flex: 1;
            }
        }

        &--left {
            display: flex;
            flex-direction: column;
            gap: 24px;

            h2 {
                max-width: 75%;
            }

            div {
                display: flex;
                gap: 24px;
            }
        }
    }
}
