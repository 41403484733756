/********************
    HERO
********************/
.hero {
    position: relative;

    margin-top: 108px;

    height: calc(100vh - 108px);

    @include mediamaxwidth(640px) {
        margin-top: 72px;
        height: calc(100vh - 72px);
    }

    @include mediamaxwidth(432px) {
        height: calc(100vh - 90px);
        margin-top: 90px;
    }

    &__title {
        display: flex;
        flex-direction: column;

        font-size: 5rem;
        font-weight: 400;

        line-height: 6rem;

        color: $white;

        margin-top: 11%;

        @include mediamaxwidth(640px) {
            font-size: 4rem;
            line-height: 5.2rem;

            margin-top: 50px;
        }

        @include mediamaxwidth(432px) {
            font-size: 3.2rem;
            line-height: 4.5rem;

            margin-top: 32px;

            text-align: center;

            span {
                display: block;
            }
        }
    }

    .illustration {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        width: 100%;

        z-index: -1;

        overflow: hidden;

        .ground {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;

            width: 100%;
            height: 50%;

            clip-path: polygon(70% 0, 100% 30%, 100% 100%, 0 100%, 0 50%);

            backdrop-filter: blur(6px) saturate(120%);
            -webkit-backdrop-filter: blur(6px) saturate(120%);
            filter: blur(6px) saturate(120%);

            &::before {
                content: "";

                position: absolute;
                top: -6px;
                left: -6px;
                right: -6px;
                bottom: 0;

                background: linear-gradient(
                        to bottom,
                        rgba($white, 0.6),
                        transparent
                    ),
                    linear-gradient(to bottom, rgba($dark, 0.08), transparent);
            }
        }

        .cube {
            position: absolute;

            width: 220px;
            height: 182px;

            transform-origin: left;

            clip-path: polygon(
                50% 0%,
                100% 14px,
                100% calc(100% - 20px),
                50% 100%,
                0% calc(100% - 20px),
                0% 14px
            );

            backdrop-filter: blur(8px) saturate(120%);
            -webkit-backdrop-filter: blur(6px) saturate(120%);
            filter: blur(8px) saturate(120%);

            &__face {
                position: relative;

                width: 220px;
                height: 28px;

                clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);

                &::before {
                    content: "";

                    position: absolute;
                    inset: -8px;

                    background: linear-gradient(
                            to bottom,
                            rgba($white, 0.4),
                            rgba($white, 0.1)
                        ),
                        linear-gradient(
                            to top,
                            rgba($dark, 0.08),
                            rgba($dark, 0.05)
                        );

                    z-index: -1;
                }

                &--left,
                &--right {
                    position: absolute;
                    top: 14px;

                    width: 110px;
                    height: 168px;
                }

                &--left {
                    left: 0;

                    clip-path: polygon(
                        0% 0%,
                        100% 14px,
                        100% 100%,
                        0% calc(100% - 20px)
                    );

                    &::before {
                        content: "";

                        position: absolute;
                        inset: -8px;

                        background: linear-gradient(
                                to bottom right,
                                rgba($white, 0.8),
                                rgba($white, 0.3)
                            ),
                            linear-gradient(
                                to bottom left,
                                rgba($dark, 0.08),
                                rgba($dark, 0.025)
                            );

                        z-index: -1;
                    }
                }

                &--right {
                    right: 0;

                    clip-path: polygon(
                        0 14px,
                        100% 0%,
                        100% calc(100% - 20px),
                        0% 100%
                    );

                    &::before {
                        content: "";

                        position: absolute;
                        inset: -8px;

                        background: linear-gradient(
                                to bottom left,
                                rgba($white, 0.7),
                                rgba($white, 0.2)
                            ),
                            linear-gradient(
                                to bottom right,
                                rgba($dark, 0.125),
                                rgba($dark, 0.025)
                            );

                        z-index: -1;
                    }
                }
            }

            &:nth-child(1) {
                top: 7%;
                left: 53.25%;

                @include mediamaxwidth(1690px) {
                    top: 7%;
                    left: 53.25%;
                }

                @include mediamaxwidth(1440px) {
                    top: 5%;
                    left: 52%;
                }
            }

            &:nth-child(2) {
                top: 7%;
                left: 63%;

                @include mediamaxwidth(1690px) {
                    top: 7%;
                    left: 63%;
                }

                @include mediamaxwidth(1440px) {
                    top: 9%;
                    left: 63%;
                }
            }

            &:nth-child(3) {
                top: 10%;
                left: 78.5%;

                @include mediamaxwidth(1690px) {
                    top: 10%;
                    left: 80.5%;
                }

                @include mediamaxwidth(1440px) {
                    top: 11%;
                    left: 80.5%;
                }
            }

            &:nth-child(4) {
                top: 16%;
                left: 98.5%;

                @include mediamaxwidth(1690px) {
                    top: 16%;
                    left: 98.5%;
                }
            }

            &:nth-child(5) {
                top: 30%;
                left: 65%;

                @include mediamaxwidth(1690px) {
                    top: 30%;
                    left: 65%;
                }
            }

            &:nth-child(6) {
                top: 30%;
                left: 61%;

                @include mediamaxwidth(1690px) {
                    top: 30%;
                    left: 59.5%;
                }

                @include mediamaxwidth(1440px) {
                    top: 30%;
                    left: 61%;
                }
            }

            &:nth-child(7) {
                top: 30%;
                left: 82.5%;

                @include mediamaxwidth(1690px) {
                    top: 30%;
                    left: 86%;
                }
            }

            &:nth-child(8) {
                top: 42%;
                left: 52%;

                @include mediamaxwidth(1690px) {
                    top: 42%;
                    left: 50%;
                }

                @include mediamaxwidth(1440px) {
                    top: 40%;
                    left: 51%;
                }
            }

            &:nth-child(9) {
                top: 48%;
                left: 68.5%;

                @include mediamaxwidth(1690px) {
                    top: 48%;
                    left: 68.5%;
                }

                @include mediamaxwidth(1440px) {
                    top: 46%;
                    left: 68.5%;
                }
            }

            &:nth-child(10) {
                top: 50%;
                left: 81%;

                @include mediamaxwidth(1690px) {
                    top: 50%;
                    left: 81%;
                }
            }

            &:nth-child(11) {
                top: 66%;
                left: 63%;

                @-moz-document url-prefix() {
                    & {
                        display: none;
                    }
                }

                @include mediamaxwidth(1690px) {
                    top: 66%;
                    left: 58%;
                }

                @include mediamaxwidth(1440px) {
                    top: 66%;
                    left: 62%;
                }
            }
        }
    }
}

/********************
    PROJECTS
********************/
.projects {
    position: relative;

    margin: -200px 0 100px;

    @include mediamaxwidth(640px) {
        margin: -120px 0 100px;
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        grid-gap: 24px;
    }

    &__col {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-end;
    }
}

/********************
    EXPERIENCES
********************/
.experiences {
    padding: 160px 0 100px;

    background: linear-gradient(to bottom, transparent, rgba($dark, 0.8));

    @include mediamaxwidth(640px) {
        padding: 100px 0 40px;
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-gap: 24px;
    }

    &__col {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-end;

        grid-area: 1 / 2 / 2 / 3;

        &:first-child {
            grid-area: 1 / 1 / 3 / 2;
        }

        &:last-child {
            grid-area: 2 / 2 / 3 / 3;
        }

        > * {
            height: 100%;
        }
    }
}