.card {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    padding: 24px;

    color: $white;

    border: 1px solid rgba($white, 0.2);
    border-radius: 20px;

    background: linear-gradient(
            to bottom right,
            rgba($white, 0.3),
            rgba($white, 0.1)
        ),
        linear-gradient(to bottom left, rgba($dark, 0.08), rgba($dark, 0.025));

    box-shadow: 0 8px 16px 0 rgba($white, 0.2);

    backdrop-filter: blur(6px) saturate(120%);
    -webkit-backdrop-filter: blur(6px) saturate(120%);

    transform-style: preserve-3d;

    &--hover:hover {
        box-shadow: 0 16px 32px 0px rgba($white, 0.3),
            0 8px 16px 0 rgba($dark, 0.05);
    }

    &--dark {
        border: 1px solid rgba($dark, 0.05);

        background: linear-gradient(
                to bottom right,
                rgba($dark, 0.2),
                rgba($dark, 0.05)
            ),
            linear-gradient(
                to bottom left,
                rgba($white, 0.08),
                rgba($white, 0.025)
            );

        box-shadow: 0 8px 16px 0 rgba($dark, 0.2);

        backdrop-filter: blur(8px) brightness(120%) saturate(120%);
        -webkit-backdrop-filter: blur(8px) brightness(120%) saturate(120%);

        &:hover {
            box-shadow: 0 16px 32px 0px rgba($dark, 0.2),
                0 8px 16px 0 rgba($white, 0.05);
        }
    }

    transition: all ease-in-out 0.2s;

    cursor: pointer;

    &__cover {
        width: 100%;
        max-width: 840px;
        border-radius: 16px;
    }

    &__header {
        display: flex;
        align-items: center;

        justify-content: space-between;

        margin-bottom: 64px;
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 40px;
        height: 40px;

        border-radius: 12px;

        background-color: rgba($white, 0.3);

        img {
            width: 24px;
            height: 24px;
        }
    }

    &__date {
        font-size: 0.875rem;
        font-weight: 300;
        opacity: 0.72;
    }

    &__body {
        display: flex;
        flex-direction: column;

        gap: 32px;
    }

    &__award,
    &__location {
        font-size: 1rem;
        font-weight: 300;
        line-height: 1rem;

        text-transform: capitalize;

        opacity: 0.96;
    }

    &__title {
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 1.75rem;
    }

    &__tasks {
        display: flex;
        flex-direction: column;

        gap: 4px;
    }

    &__task {
        font-size: 0.875rem;
        font-weight: 300;
        line-height: 1rem;

        opacity: 0.8;
    }

    .separator {
        width: 60px;
        height: 10px;
    }
}
